import React from "react";
import { makeStyles } from "@mui/styles";
import Typography from "@mui/material/Typography";

export enum ChipBackground {
  colour = "Colour",
  white = "White",
  clear = "Clear",
}

const useStyles = (
  pinned: boolean,
  colour: string,
  background: ChipBackground,
  top?: number,
  left?: number,
  uppercase?: boolean,
  textColourOverride?: string
) =>
  makeStyles((theme) => ({
    chip: {
      textAlign: "center",
      textTransform: uppercase ? "uppercase" : "none",
      zIndex: 2,
      border: `1px solid ${
        background === ChipBackground.colour ? undefined : colour
      }`,
      backgroundColor:
        background === ChipBackground.colour
          ? colour
          : background === ChipBackground.white
          ? theme.palette.common.white
          : "transparent",
      color: !!textColourOverride
        ? textColourOverride
        : background === ChipBackground.colour
        ? theme.palette.common.white
        : colour,
      borderRadius: pinned ? "0px 16px 16px 0px" : "16px",
      opacity: 1,
      padding: theme.spacing(0.7, 1.5),
      top: pinned ? (!!top ? top : 15) : undefined,
      left: pinned ? (!!left ? left : -3) : undefined,
      whiteSpace: "nowrap",
      fontSize: "14px",
      lineHeight: "18px",
      position: pinned ? "absolute" : undefined,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    chipIcon: {
      marginLeft: "auto",
      cursor: "pointer",
      paddingLeft: theme.spacing(0.5),
      "& svg": {
        verticalAlign: "middle",
        color: "currentColor",
        width: 20,
        opacity: 0.6,
      },
    },
  }))();

export type ChipProps = {
  label: string | JSX.Element;
  top?: number;
  left?: number;
  colour?: string;
  pinned?: boolean;
  background?: ChipBackground;
  uppercase?: boolean;
  textColourOverride?: string;
  endIcon?: JSX.Element;
  onClickEndIcon?: () => void;
};

export const Chip: React.FC<ChipProps> = ({
  label,
  top,
  left,
  colour = "#22A672",
  pinned = false,
  background = ChipBackground.white,
  uppercase = false,
  textColourOverride,
  endIcon,
  onClickEndIcon,
}: ChipProps) => {
  const classes = useStyles(
    pinned,
    colour,
    background,
    top,
    left,
    uppercase,
    textColourOverride
  );

  return (
    <Typography component="div" className={classes.chip} data-testid="chipRoot">
      {typeof label === "string" ? <strong>{label}</strong> : label}
      {endIcon && (
        <div className={classes.chipIcon} onClick={onClickEndIcon}>
          {endIcon}
        </div>
      )}
    </Typography>
  );
};
