import React from "react";
import { makeStyles } from "@mui/styles";
import { Container } from "@mui/material";
import { NavigationContainer } from "../../components/SemanticTheme/NavigationContainer";
import StandardCardComponent from "./StandardCardTemplate";
import { RichContentCardContainer } from "../../components/SemanticTheme/RichContentCard";
import { withContent } from "../../utils/TemplateWithContent";
import FHSwiperSlider from "@components/SemanticTheme/FHSwiperSlider";
import { RichContentCardsNavigationContainerEntity } from "src/graphql/generated-strapi/types";
import { updateAbsoluteLink } from "@components/Utils";

const useStyles = (showPaddingTop: boolean, showPaddingBottom: boolean) =>
  makeStyles((theme) => ({
    container: {
      paddingTop: theme.spacing(showPaddingTop ? 3.5 : 0),
      paddingBottom: theme.spacing(showPaddingBottom ? 3.5 : 0),
      "@media (max-width:600px)": {
        paddingTop: theme.spacing(showPaddingTop ? 2.5 : 0),
        paddingBottom: theme.spacing(showPaddingBottom ? 2.5 : 0),
      },
    },
  }))();

type RichCardNavContainer = {
  pageImgQuality?: number;
  additionalProps: { [key: string]: string | boolean };
} & RichContentCardsNavigationContainerEntity;

const RichContentCardsNavigationContainerComponent: React.FC<
  RichCardNavContainer
> = (props: RichCardNavContainer): JSX.Element => {
  const classes = useStyles(
    props?.attributes?.ShowTopPadding,
    props?.attributes?.ShowBottomPadding,
  );
  const subtitle: string = props?.attributes?.SubTitle?.replace(
    /(<p[^>]+?>|<p>|<\/p>)/gim,
    "",
  ); // Strip <p> tags from the subTitle

  const locationPinOnLandingPageCards =
    !!props?.additionalProps?.locationPinOnLandingPageCards;

  return (
    <Container className={classes.container}>
      <NavigationContainer
        ctaText={props?.attributes?.PrimaryCallToActionText}
        ctaURL={updateAbsoluteLink(
          props?.attributes?.Primaryact_Ref?.data?.attributes?.PageRoute,
        )}
        title={props?.attributes?.Title}
        subTitle={subtitle}
        titleUrl={updateAbsoluteLink(
          props?.attributes?.TitleActRef?.data?.attributes?.PageRoute,
        )}
      >
        <RichContentCardContainer>
          {props?.attributes?.EnableCarouselView
            ? props?.attributes?.standard_cards?.data?.length > 0 && (
                <FHSwiperSlider
                  content={props.attributes.standard_cards.data}
                  pageImgQuality={props?.pageImgQuality}
                  containerImgQuality={props?.attributes?.ImageQuality}
                  locationPinOnLandingPageCards={locationPinOnLandingPageCards}
                />
              )
            : props?.attributes?.standard_cards?.data.map((cards, i) => {
                return (
                  <React.Fragment key={`standard-card-${i}`}>
                    <StandardCardComponent
                      {...cards?.attributes}
                      pageImgQuality={props?.pageImgQuality}
                      containerImgQuality={props?.attributes?.ImageQuality}
                      key={i}
                      locationPinOnLandingPageCards={
                        locationPinOnLandingPageCards
                      }
                    />
                  </React.Fragment>
                );
              })}
        </RichContentCardContainer>
      </NavigationContainer>
    </Container>
  );
};
const RichContentCardsNavigationContainerComponentTemplate = (props) => {
  const content = props?.content?.richCards?.data;
  const pageImgQuality = props?.pageImgQuality;
  const additionalProps = props?.additionalProps;
  return withContent(
    RichContentCardsNavigationContainerComponent,
    content,
    undefined,
    pageImgQuality,
    additionalProps,
  );
};
export default RichContentCardsNavigationContainerComponentTemplate;
