import React, { useRef } from "react";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    overflow: "hidden",
    position: "relative",
    height: 25,
    color: (props: any) => {
      const { textColour } = props;
      switch (textColour) {
        case "primary":
          return theme.palette.primary.main;
        case "secondary":
          return theme.palette.secondary.main;
        case "forestGreen":
          return theme.palette.primary.dark;
        default:
          return theme.palette.text.primary;
      }
    },
    width: (props: any) => (props.isBold ? "calc(100% + 10px)" : "auto"),
    [theme.breakpoints.down("md")]: {
      height: (props: any) => (props.isMobile ? "auto" : 25),
      display: (props: any) => (props.isMobile ? "flex" : "block"),
      alignItems: (props: any) => (props.isMobile ? "center" : "initial"),
    },
  },
  content: {
    position: "absolute",
    overflow: "hidden",
    display: "inline",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    transform: "translateX(0)",
    "&:hover": {
      transform: (props: any) =>
        props.containerWidth
          ? `translateX(calc(${props.containerWidth}px - 100%))`
          : "translateX(0)",
      transitionDuration: "0.03s",
    },
    "&:not(:hover)": {
      transform: "translateX(0)",
      transitionDuration: "0.03s",
    },
  },
  hiddenDiv: {
    visibility: "hidden",
    opacity: 0,
  },
  highlighted: {
    textDecoration: "underline",
  },
  auto: {
    textDecoration: "auto",
  },
  isBold: {
    fontFamily: theme.typography.h2.fontFamily,
    fontWeight: 600,
  },
}));

export interface TextOverflowScrollProps {
  overFlowText: string;
  id?: string;
  containerWidth?: number | undefined;
  parentElementReference?: React.RefObject<HTMLInputElement>;
  isHighlighted?: boolean;
  textColour?: "primary" | "secondary" | "forestGreen" | "text";
  isBold?: boolean;
  isMobile?: boolean;
}

export const TextOverflowScroll: React.FC<TextOverflowScrollProps> = ({
  overFlowText,
  id,
  containerWidth: propContainerWidth,
  isHighlighted = false,
  textColour = "text",
  parentElementReference,
  isBold = false,
  isMobile = false,
}: TextOverflowScrollProps) => {
  const classes = useStyles({
    containerWidth: propContainerWidth,
    textColour,
    isBold,
    isMobile,
  });

  return (
    <div className={classes.root}>
      <div
        className={`${classes.content} ${
          isHighlighted ? classes.highlighted : classes.auto
        } ${isBold ? classes.isBold : ""}`}
        data-testid="overflowText"
        id={id || overFlowText}
        ref={parentElementReference} // Use parentElementReference as ref
      >
        {overFlowText}
      </div>
      <div
        className={`${classes.hiddenDiv} ${
          isHighlighted ? classes.highlighted : classes.auto
        }`}
      >
        {overFlowText}
      </div>
    </div>
  );
};

export default TextOverflowScroll;
