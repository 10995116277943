import React, { PropsWithChildren, useEffect } from "react";
import IconButton from "@mui/material/IconButton";
import Link from "@mui/material/Link";
import { makeStyles } from "@mui/styles";
import { Theme, Typography } from "@mui/material";
import { ModalImageProps } from "../ImageModalLink";
import { ImageModalLink } from "../ImageModalLink/index";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { PreLoadImage } from "../PreLoadImage";
import { Skeleton } from "@mui/material";
import { Fade } from "@mui/material";
import { FHNextImage, ImageLoader } from "../FHNextImage";
import classNames from "classnames";
import { useIsMobile } from "src/hooks/hooks";

const useStyles = makeStyles((theme: Theme) => ({
  root: (props: ImageGalleryCarouselStyleProps) => ({
    height: props.containerRatio ? 0 : "100%",
    position: "relative",
    paddingTop: props.containerRatio
      ? `${(props.containerRatio.y / props.containerRatio.x) * 100}%`
      : 0,
    maxWidth: props.maxWidth,
    margin: props.centered ? "0 auto" : "",
  }),
  modalContainer: {
    position: "absolute",
    height: "100%",
    width: "100%",
    top: 0,
    left: 0,
  },
  imageContainer: {
    position: "relative",
    display: "flex",
    alignItems: "center",
    overflow: "hidden",
    height: "100%",
  },
  imageIndicatorContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    position: "relative",
    height: "50px",
    marginTop: "-50px",
    "& a:hover": {
      textDecoration: "none",
    },
    boxSizing: "content-box",
  },
  boxNavigator: {
    marginRight: theme.spacing(1),
    width: 9,
    height: 9,
    border: "2px solid white",
    "&.selected": {
      backgroundColor: "white",
    },
    cursor: "pointer",
    "&:last-child": {
      marginRight: 0,
    },
  },
  image: {
    width: "100%",
    height: "100%",
    position: "absolute",
    top: 0,
    left: 0,
  },
  imageHover: {
    cursor: "pointer",
  },
  directionalArrows: {
    display: "flex",
    position: "relative",
    width: "100%",
    height: "100%",
    justifyContent: "space-between",
    alignItems: "center",
  },
  arrows: {
    zIndex: 1,
    position: "relative",
  },
}));

type ImageGalleryCarouselStyleProps = {
  maxWidth?: string | number;
  centered?: boolean;
  fixedHeight?: string | number;
  containerRatio?: { x: number; y: number };
  selectedImageIndex?: (imageIndex: number) => void;
  imageIndex?: number;
  paginationHelperText?: string;
};

export type CarouselImageProps = ModalImageProps & {
  smallImageUrl: string;
};

export type ImageGalleryCarouselProps = {
  images: CarouselImageProps[];
  imageLoader?: ImageLoader;
  removeSquaresOnImage?: boolean;
  makeGalleryImagesSwipeable?: boolean;
} & ImageGalleryCarouselStyleProps;

export const ImageGalleryCarousel: React.FC<ImageGalleryCarouselProps> = ({
  fixedHeight,
  images,
  maxWidth,
  centered = true,
  containerRatio,
  selectedImageIndex,
  imageIndex = 0,
  paginationHelperText = "/",
  imageLoader,
  removeSquaresOnImage = false,
  makeGalleryImagesSwipeable = false,
}: PropsWithChildren<ImageGalleryCarouselProps>) => {
  const classes = useStyles({
    fixedHeight,
    maxWidth,
    centered,
    containerRatio,
  });
  const [imgPosition, updatePosition] = React.useState(imageIndex);
  const [allImagesLoaded, setAllImagesLoaded] = React.useState(false);
  const [clickedImagePosition, setClickedImagePosition] = React.useState<
    null | number
  >(null);
  const loadedImages = React.useRef<number[]>([]);
  const touchStart = React.useRef(null);
  const isMobile = useIsMobile();
  const isChecked = (pos: number) => imgPosition === pos;
  const modalImages = images.map((image) => {
    return {
      imageUrl: image.imageUrl || image.smallImageUrl,
      altText: image.altText,
    };
  });
  const totalImages = modalImages.length;

  useEffect(() => {
    selectedImageIndex && selectedImageIndex(imgPosition);
  }, [imgPosition]);

  const handlePositionRight = (
    e: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
  ) => {
    e.stopPropagation();
    updatePosition(imgPosition === totalImages - 1 ? 0 : imgPosition + 1);
  };

  const handlePositionLeft = (
    e: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
  ) => {
    e.stopPropagation();
    updatePosition(imgPosition === 0 ? totalImages - 1 : imgPosition - 1);
  };

  const handleTouchStart = (e) => {
    if (makeGalleryImagesSwipeable && isMobile)
      touchStart.current = e.touches[0].clientX;
  };

  const handleTouchMove = (e) => {
    if (makeGalleryImagesSwipeable && isMobile) {
      if (touchStart.current === null) {
        return;
      }

      const touchEnd = e.touches[0].clientX;
      const swipe = touchEnd - touchStart.current;

      if (swipe > 5) {
        // Swipe left
        handlePositionLeft(e);
      } else if (swipe < -5 && isMobile) {
        // Swipe right
        handlePositionRight(e);
      }

      touchStart.current = null;
    }
  };

  return (
    <div className={classes.root} data-testid="galleryCarouselRoot">
      <div className={classes.modalContainer}>
        <ImageModalLink
          modalImages={modalImages}
          position={imgPosition}
          paginationHelperText={paginationHelperText}
          setOnClickImage={() => {
            setClickedImagePosition(null);
          }}
          clickedImagePosition={clickedImagePosition}
        >
          <div
            className={classes.imageContainer}
            onTouchStart={handleTouchStart}
            onTouchMove={handleTouchMove}
          >
            {allImagesLoaded && totalImages > 1 && (
              <div className={classes.directionalArrows}>
                <label htmlFor="icon-button-file" className={classes.arrows}>
                  <IconButton
                    color="primary"
                    aria-label="left"
                    component="span"
                    onClick={handlePositionLeft}
                    data-testid="open-button"
                  >
                    <ArrowBackIosNewIcon style={{ color: "white" }} />
                  </IconButton>
                </label>
                <label htmlFor="icon-button-file" className={classes.arrows}>
                  <IconButton
                    color="primary"
                    aria-label="right"
                    component="span"
                    onClick={handlePositionRight}
                    data-testid="open-button"
                  >
                    <ArrowForwardIosIcon style={{ color: "white" }} />
                  </IconButton>
                </label>
              </div>
            )}

            {images.map((image, i) => (
              <React.Fragment key={`image-${i}`}>
                {!allImagesLoaded && isChecked(i) && (
                  <PreLoadImage
                    url={image.smallImageUrl}
                    loaderOverlay={
                      isChecked(i) ? (
                        <Skeleton
                          className={classes.image}
                          animation="wave"
                          variant="rectangular"
                        />
                      ) : undefined
                    }
                  />
                )}
                <Fade timeout={500} in={isChecked(i)}>
                  <div
                    className={classes.image}
                    data-testid="galleryCarouselImage"
                  >
                    <FHNextImage
                      src={image.smallImageUrl}
                      alt={"title"}
                      layout="fill"
                      objectFit="cover"
                      loading="lazy"
                      loader={imageLoader}
                      onLoad={() => {
                        loadedImages.current.push(i);
                        if (loadedImages.current.length === images.length) {
                          setAllImagesLoaded(true);
                        }
                      }}
                    />
                  </div>
                </Fade>
              </React.Fragment>
            ))}
          </div>
          {!removeSquaresOnImage && allImagesLoaded && totalImages > 1 && (
            <div className={classes.imageIndicatorContainer}>
              {images.map((image, i) => (
                <Link
                  aria-label={`${i}-indicator`}
                  key={`${i}-indicator`}
                  href={image.imageUrl}
                  onClick={(
                    e: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
                  ) => {
                    e.preventDefault();
                    updatePosition(i);
                  }}
                  className={`${classes.boxNavigator}${
                    imgPosition === i ? " selected" : ""
                  }`}
                  data-testid="galleryCarouselImageLink"
                >
                  &nbsp;
                </Link>
              ))}
            </div>
          )}
        </ImageModalLink>
      </div>
    </div>
  );
};
