import { makeStyles } from "@mui/styles";
import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";
import {
  DateRange,
  DropdownName,
  GuestLabels,
  GuestType,
  LocationsDropdownItem,
  SortedLocation,
} from "../../components/SemanticTheme/BookingForm";
import {
  cleanseGuid,
  generateBookingQueryString,
  getMonthNumberFromName,
  getPartialMonthNameFromMonthNumber,
  useNextRouterPathname,
  useNextRouterPathnameWithQuery,
} from "../../utils/common";
import React, { useCallback } from "react";
import { useDispatch } from "react-redux";
import { StepperTag } from "../../interfaces/booking";
import { useEffect, useState } from "react";
import { useRouter } from "next/router";
import { FocusedInputShape } from "react-dates";
import { JourneyTypes } from "../../interfaces/stepper";
import { Container, Grid, Paper, Skeleton } from "@mui/material";
import LocationMapComponent from "./LocationMapTemplate";
import { withContent } from "../../utils/TemplateWithContent";
import { DynamicBookingForm } from "./DynamicComponents";
import {
  createGuestLabels,
  setAdults,
  setBedrooms,
  setChildren,
  setPresentMonth,
  setPresentYear,
  setDda,
  setInfants,
  setPets,
  setSelectedDates,
  setSelectedLocationIds,
  getAvailableDates,
  getLocationsAction,
  selectLocation,
  selectAllLocations,
  setBookingStepsAdditionalData,
  setCabinSearchFilter,
  createCardPaymentReset,
  setActiveStepperSetting,
  createCabinSearchRedirect,
  createLocationSearch,
  resetActiveBasketAndBooking,
  getCalendarSummary,
  updateGuestLabel,
  makeGuestsLabel,
} from "@/store/actions";
import {
  useBookingQueryStringSelector,
  useBookingSearchStateSelector,
  useActiveStepSelector,
  useFindStepSelector,
  useSiteSettingsSelector,
  useCabinFilterSelector,
  useDatesSelector,
  useLocationsSelector,
  useGetReservationFromReferralSelector,
} from "@/store/selectors";
import { filterXSS } from "xss";
import { useIsMobile } from "src/hooks/hooks";
import { postSSTBookingSearch } from "public/script/postsst";
import RichText from "./RichText";
import { isAfter } from "date-fns";
import { usecalendarSummarySelector } from "src/store/selectors/calendar-summary.selector";
import { isBrowser, processRichText } from "@components/Utils";
import classNames from "classnames";
import { BookingWidget } from "src/graphql/generated-strapi/types";
import {
  FHNextImage,
  ImageLoader,
} from "../../components/SemanticTheme/FHNextImage";

const sortObject = (o: SortedLocation) =>
  Object.keys(o)
    .sort()
    .reduce((r, k) => (((r as any)[k] = (o as any)[k]), r), {});

let timer: ReturnType<typeof setTimeout>;
const makeSortedLocations = (locationItems: LocationsDropdownItem[]) => {
  const alphabeticallySortedLocations = [...(locationItems || [])].sort(
    (a, b) => (a.name > b.name ? 1 : -1),
  );

  const sorted = alphabeticallySortedLocations.reduce(
    (
      prevLocations: { [key: string]: LocationsDropdownItem },
      location: LocationsDropdownItem,
    ) => ({
      ...prevLocations,
      [location.id]: location,
    }),
    {},
  );

  const list = Object.values(sorted);
  const locationsSorted = list.reduce(
    (
      prevLocationsByCountry: SortedLocation,
      { country, id, name, region }: LocationsDropdownItem,
    ) =>
      ({
        ...prevLocationsByCountry,
        [country]: {
          ...(prevLocationsByCountry[country] || []),
          [id]: {
            id,
            name,
            region,
            country,
          },
        },
      } as SortedLocation),
    {},
  );
  return sortObject(locationsSorted);
};

const ManageBookingQuery = () => {
  const history = useRouter();
  const dispatch = useDispatch();

  const cabinStep = useFindStepSelector(StepperTag.cabins);
  const locationStep = useFindStepSelector(StepperTag.locations);
  const { bookableLocations } = useLocationsSelector();

  const {
    selectedLocationIds,
    dateRange: bookingDateRange,
    dda,
    guests,
  } = useBookingSearchStateSelector();
  const locationIds = selectedLocationIds;
  const queryData = useBookingQueryStringSelector();
  useEffect(() => {
    if (bookableLocations && bookableLocations.length > 0) {
      const allLocationIds = bookableLocations?.map((location) => location.id);
      if (queryData) {
        const allLocIdsFromQueryWithHyphens = allLocationIds?.filter((locId) =>
          (queryData?.selectedLocationIds || [""]).find(
            (queryLocId) => cleanseGuid(queryLocId) === cleanseGuid(locId),
          ),
        );

        if (queryData?.queryDataIsValid) {
          const queryStringDates = JSON.stringify(queryData.dateRange);
          const reduxDates = JSON.stringify(bookingDateRange);
          const queryStringLocationIds = JSON.stringify(
            allLocIdsFromQueryWithHyphens?.sort(),
          );
          const reduxLocationIds = JSON.stringify(locationIds.slice(0).sort());

          if (queryStringDates !== reduxDates) {
            dispatch(setSelectedDates(queryData.dateRange));
          }
          if (queryStringLocationIds !== reduxLocationIds) {
            dispatch(setSelectedLocationIds(allLocIdsFromQueryWithHyphens));
          }
        } else if (queryData.queryDataIsValid) {
          dispatch(resetActiveBasketAndBooking());
        }
      } else if (
        bookingDateRange.startDateISO &&
        bookingDateRange.endDateISO &&
        locationIds.length > 0
      ) {
        const generatedQueryString = generateBookingQueryString({
          startDate: bookingDateRange.startDateISO,
          endDate: bookingDateRange.endDateISO,
          locationIds,
          dda,
          guests,
        });
        const url = useNextRouterPathname(history);
        const isOnASearchPage =
          url &&
          ((cabinStep?.url || "") === url || (locationStep?.url || "") === url);
        if (
          isOnASearchPage &&
          filterXSS(window.location.search) !== generatedQueryString
        ) {
          history.push({
            pathname: url,
            search: generatedQueryString,
          });
        }
      }
    }
  }, [bookableLocations]);

  return null;
};
export const BookingWidgetComponent = (
  props: BookingWidget & {
    additionalProps: { [key: string]: string | boolean };
    imageLoader?: ImageLoader;
  },
) => {
  const [isDropDownOpen, setIsDropDownOpen] = useState(false);

  const useStyles = makeStyles((theme) => ({
    mobileWidget: {
      marginBottom: 0,
      [theme.breakpoints.down("md")]: {
        paddingLeft: 0,
        paddingRight: 0,
      },
    },
    helpTextIcon: {
      verticalAlign: "top",
      marginRight: "5px",
    },
    searchFormMargin: {
      [theme.breakpoints.down("md")]: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
      },
    },
    container: {
      [theme.breakpoints.down("md")]: {
        padding: 0,
        margin: 0,
      },
    },
    paperRoot: {
      padding: theme.spacing(2),
      [theme.breakpoints.down("md")]: {
        minHeight: 280,
      },
    },
    hideLoader: {
      display: "none",
    },
    mobileYellowBanner: {
      borderTopLeftRadius: 0,
      borderTopRightRadius: 0,
    },
  }));

  const classes = useStyles();

  const calendarIconEl = props?.CalendarIcon?.data?.attributes?.url ? (
    <FHNextImage
      alt=""
      src={props?.CalendarIcon?.data?.attributes?.url}
      loading="lazy"
      loader={props?.imageLoader}
      width={16}
      height={16}
    />
  ) : undefined;

  const locationIconEl = props?.LocationIcon?.data?.attributes?.url ? (
    <FHNextImage
      alt=""
      src={props?.LocationIcon?.data?.attributes?.url}
      loading="lazy"
      loader={props?.imageLoader}
      width={16}
      height={16}
    />
  ) : undefined;

  const partyIconEl = props?.PartyIcon?.data?.attributes?.url ? (
    <FHNextImage
      alt=""
      src={props?.PartyIcon?.data?.attributes?.url}
      loading="lazy"
      loader={props?.imageLoader}
      width={16}
      height={16}
    />
  ) : undefined;

  const dDAIconEl = props?.DDAIcon?.data?.attributes?.url ? (
    <FHNextImage
      alt=""
      src={props?.DDAIcon?.data?.attributes?.url}
      loading="lazy"
      loader={props?.imageLoader}
      width={16}
      height={16}
    />
  ) : undefined;

  const closeIconEl = props?.CloseIcon?.data?.attributes?.url ? (
    <FHNextImage
      alt=""
      src={props?.CloseIcon?.data?.attributes?.url}
      loading="lazy"
      loader={props?.imageLoader}
      width={16}
      height={16}
    />
  ) : undefined;

  const helpTextIconEl = props?.HelpTextIcon?.data?.attributes?.url ? (
    <FHNextImage
      alt=""
      src={props?.HelpTextIcon?.data?.attributes?.url}
      loading="lazy"
      loader={props?.imageLoader}
      width={16}
      height={16}
    />
  ) : undefined;

  const history = useRouter();
  const dispatch = useDispatch();
  const isMobile = useIsMobile();
  const allowSearchSummary =
    isMobile && (props?.ShowBookingWidgetSearchSummaryInMobile || false);

  const {
    bookableLocations,
    loaded: locationsLoaded,
    loading: locationsLoading,
  } = useLocationsSelector();

  const {
    bookingChannelId,
    selectedLocationIds,
    dateRange,
    currentMonth,
    currentYear,
    guests,
    dda,
    guestLabel,
    locationsLabel,
    minNoOfBedrooms,
    guestLabels,
  } = useBookingSearchStateSelector();
  const {
    startDates: availableStartDates,
    calendarDatesWithCabinCount,
    loading: calendarDatesWithCabinCountLoading,
  } = useDatesSelector();
  const locationIds = selectedLocationIds;
  const { availableMonths, availableYears, showQuickLinks } =
    usecalendarSummarySelector();

  const activeStep = useActiveStepSelector();
  const cabinStep = useFindStepSelector(StepperTag.cabins);
  const locationStep = useFindStepSelector(StepperTag.locations);
  const activeFilters = useCabinFilterSelector();
  const { activeBookingStepIndex } = useSiteSettingsSelector();
  const { data: reservationFromReferral, referrer } =
    useGetReservationFromReferralSelector();

  const [focus, setFocus] = useState<FocusedInputShape>("startDate");

  const onFocusChange = (focusedInput: FocusedInputShape | null) => {
    setFocus(!focusedInput ? "startDate" : focusedInput);
  };

  const updateDates = () => {
    if (!locationsLoaded || locationIds?.length === 0) return;
    dispatch(
      getAvailableDates({
        bookingChannelId,
        locationIds,
        month: currentMonth,
        year: currentYear,
      }),
    );
  };

  useEffect(() => {
    if (!bookableLocations) {
      dispatch(
        getLocationsAction({
          allLocations: false,
          includeCampingLocations: false,
        }),
      );
      return;
    }
  }, []);

  useEffect(() => {
    if (!!guests && !!guestLabels && !!referrer)
      dispatch(updateGuestLabel(makeGuestsLabel(guests, dda, guestLabels)));
  }, [guests, guestLabels, dda]);

  useEffect(() => {
    const newLabels: GuestLabels = {
      multipleGuestsLabel: props?.GuestsMultiple,
      singleGuestLabel: props?.GuestSingular,
      multipleInfantsLabel: props?.InfantsMultiple,
      singleInfantLabel: props?.InfantSingular,
      multiplePetsLabel: props?.PetsMultiple,
      singlePetLabel: props?.PetSingular,
      accessibleLabel: props?.AccessibleLabel,
    };
    if (
      Object.entries(newLabels).toString() !==
      Object.entries(guestLabels).toString()
    ) {
      dispatch(createGuestLabels(newLabels));
    }
    return () => {
      clearTimeout(timer);
    };
  }, [isDropDownOpen]);

  const dropDownOpen = (dropdown?: DropdownName) => {
    setIsDropDownOpen(true);
    switch (dropdown) {
      case "dates":
        if (!locationIds.length) {
          dispatch(selectAllLocations({ checked: true }));
        }
    }
  };

  const isFormDisabled = () =>
    dateRange.startDateISO === null ||
    dateRange.endDateISO === null ||
    locationIds.length === 0;

  const onSubmit = () => {
    allowSearchSummary && setShowSearchSummary(true);
    dispatch(createCardPaymentReset());
    const currUrl = useNextRouterPathnameWithQuery(history);
    const stepIndex = activeBookingStepIndex + (locationIds.length > 1 ? 1 : 2);
    const currentPath =
      locationIds?.length > 1 ? locationStep?.url || "" : cabinStep?.url || "";
    const bookingQueryString = generateBookingQueryString({
      startDate: dateRange.startDateISO || "",
      endDate: dateRange.endDateISO || "",
      locationIds,
      dda,
      guests,
    });
    const nextPageUrl = `${currentPath}${bookingQueryString}`;

    dispatch(
      setActiveStepperSetting({
        activeJourneyStep: stepIndex,
        activeJourney: JourneyTypes.bookingJourney,
      }),
    );

    history
      .push({
        pathname: currentPath,
        query: bookingQueryString.replace("?", ""),
      })
      .then(() => {
        if (
          activeStep?.tag === StepperTag.locations &&
          currUrl.indexOf("location") > 0
        ) {
          dispatch(
            setBookingStepsAdditionalData({
              locationIds,
            }),
          );
          const referrer = isBrowser() ? document.referrer : "";
          const cabinTypeId = reservationFromReferral?.cabinTypeId?.toString();
          const bedsAttempted =
            reservationFromReferral?.noOfBedrooms?.toString();
          postSSTBookingSearch(
            locationIds,
            dateRange,
            guests,
            dda,
            nextPageUrl,
            referrer,
            cabinTypeId,
            bedsAttempted,
          );
          dispatch(createLocationSearch());
        } else if (
          activeStep?.tag === StepperTag.cabins &&
          currUrl.indexOf("cabin") > 0
        ) {
          if (activeFilters?.length > 0) {
            dispatch(
              setCabinSearchFilter({
                sortMethod: false,
                filters: activeFilters,
              }),
            );
          } else {
            dispatch(
              setBookingStepsAdditionalData({
                locationId: locationIds[0],
              }),
            );
            const referrer = isBrowser() ? document.referrer : "";
            const cabinTypeId =
              reservationFromReferral?.cabinTypeId?.toString();
            const bedsAttempted =
              reservationFromReferral?.noOfBedrooms?.toString();
            postSSTBookingSearch(
              locationIds,
              dateRange,
              guests,
              dda,
              nextPageUrl,
              referrer,
              cabinTypeId,
              bedsAttempted,
            );
            dispatch(createCabinSearchRedirect(history));
          }
        }
      });
  };

  const setCurrentMonth = (month: number) => dispatch(setPresentMonth(month));

  const setCurrentYear = (year: number) => dispatch(setPresentYear(year));

  const setDateRange = (range: DateRange) => dispatch(setSelectedDates(range));

  const handleUpdateGuests = (type: GuestType, value: number) => {
    switch (type) {
      case "adults": {
        dispatch(setAdults(value));
        break;
      }
      case "children": {
        dispatch(setChildren(value));
        break;
      }
      case "infants": {
        dispatch(setInfants(value));
        break;
      }
      case "pets": {
        dispatch(setPets(value));
        break;
      }
      case "bedrooms": {
        dispatch(setBedrooms(value));
        break;
      }
      default:
    }
  };

  const toggleDda = (toggled?: boolean) =>
    dispatch(setDda(toggled !== undefined ? false : !dda));

  // locations dropdown logic starts here
  const [popupTimer, setPopupTimer] = useState(0);
  const [infocardIndex, setInfocardIndex] = useState<string>("");
  const [listOfCheckedLocationIds, setListOfCheckedLocationIds] =
    useState<string[]>();
  const [sortedLocations, setSortedLocations] = useState<SortedLocation>();
  const [hoverLocationId, setHoverLocationId] = useState<string>("");
  const [isMapActive, setIsMapActive] = useState(false);
  const [showSearchSummary, setShowSearchSummary] =
    useState(allowSearchSummary);
  const [selectedYearFromQuickLinks, setSelectedYearFromQuickLinks] =
    useState(currentYear);
  const [selectedMonthFromQuickLinks, setSelectedMonthFromQuickLinks] =
    useState(getPartialMonthNameFromMonthNumber(currentMonth));
  const [isLoading, setIsLoading] = useState(true);

  const areAllSelected = useCallback(() => {
    return (
      (listOfCheckedLocationIds || []).length ===
      (bookableLocations?.length || 0)
    );
  }, [listOfCheckedLocationIds, bookableLocations]);

  useEffect(() => {
    setSelectedYearFromQuickLinks(currentYear);
    setSelectedMonthFromQuickLinks(
      getPartialMonthNameFromMonthNumber(currentMonth),
    );
  }, [currentYear, currentMonth]);

  useEffect(() => {
    if (props?.additionalProps?.locationId !== "" && !!bookableLocations) {
      const locationIdFromPage = String(
        props?.additionalProps?.locationId,
      ).toLowerCase();
      const isLocationIdAvailableInBookableLocations = bookableLocations.find(
        (bookableLocation) => bookableLocation.id === locationIdFromPage,
      );
      if (isLocationIdAvailableInBookableLocations) {
        dispatch(
          selectLocation({
            selectedLocationIds: [locationIdFromPage],
            checked: true,
            bookingLocation: isLocationIdAvailableInBookableLocations,
          }),
        );
      }
    }
  }, [props?.additionalProps?.locationId, bookableLocations]);

  useEffect(() => {
    if (!locationsLoaded) return;
    const sortedCheckedLocationIds = locationIds.slice(0).sort();

    const oldIds = JSON.stringify(listOfCheckedLocationIds);
    const newIds = JSON.stringify(sortedCheckedLocationIds);
    if (oldIds !== newIds) {
      // no need to re-render if ids are the same
      setSortedLocations(makeSortedLocations(bookableLocations as any[])); //Should only be updated externally
      setListOfCheckedLocationIds(sortedCheckedLocationIds);
    }
  }, [bookableLocations, locationsLoaded, locationIds]);

  useEffect(() => {
    if (selectedYearFromQuickLinks > 0 && !!availableMonths) {
      dispatch(setPresentYear(selectedYearFromQuickLinks));
      dispatch(
        setPresentMonth(
          getMonthNumberFromName(
            availableMonths[selectedYearFromQuickLinks][0],
          ),
        ),
      );
    }
  }, [selectedYearFromQuickLinks]);

  useEffect(() => {
    if (selectedYearFromQuickLinks > 0) {
      dispatch(
        setPresentMonth(getMonthNumberFromName(selectedMonthFromQuickLinks)),
      );
    }
  }, [selectedMonthFromQuickLinks]);

  useEffect(() => {
    updateDates();
  }, [currentMonth, currentYear, locationIds.length]); // Only re-run the effect if count changes

  useEffect(() => {
    dispatch(getCalendarSummary());
  }, []);

  const handleToggle = useCallback(() => {
    if (sortedLocations) {
      const checked = !areAllSelected();
      if (checked) {
        bookableLocations &&
          bookableLocations.length > 0 &&
          setListOfCheckedLocationIds(
            [...bookableLocations.map((item) => item.id)].sort(),
          );
      } else {
        setListOfCheckedLocationIds([]);
      }
      dispatch(selectAllLocations({ checked }));
    }
  }, [
    sortedLocations,
    areAllSelected,
    bookableLocations,
    setListOfCheckedLocationIds,
    dispatch,
  ]);

  const handleClearSelections = useCallback(() => {
    const checked = false;
    setListOfCheckedLocationIds([]);
    setInfocardIndex("");
    dispatch(selectAllLocations({ checked }));
  }, [setListOfCheckedLocationIds, setInfocardIndex, dispatch]);

  const handleUpdateLocation = useCallback(
    (
      country: string,
      locationId: string,
      checked: boolean,
      actionFromMap: boolean,
    ) => {
      setIsMapActive(actionFromMap);

      if (sortedLocations) {
        const updatedCheckedList: string[] = checked
          ? [...(listOfCheckedLocationIds || []), locationId].sort()
          : (listOfCheckedLocationIds || [])
              .filter((id: string) => locationId !== id)
              .sort();

        setListOfCheckedLocationIds(updatedCheckedList);

        if (sortedLocations[country] && sortedLocations[country][locationId]) {
          const locationitem = sortedLocations[country][locationId];
          dispatch(
            selectLocation({
              selectedLocationIds: updatedCheckedList,
              checked,
              bookingLocation: locationitem,
            }),
          );
        }

        if (checked) {
          setInfocardIndex(locationId);

          if (popupTimer > 0) {
            clearTimeout(timer);
            timer = setTimeout(() => {
              setInfocardIndex("");
            }, popupTimer);
          }
        }
      }
    },
    [
      setIsMapActive,
      sortedLocations,
      listOfCheckedLocationIds,
      setListOfCheckedLocationIds,
      dispatch,
      setInfocardIndex,
      popupTimer,
    ],
  );

  const mobileYellowBannerDeskandTab =
    !!props?.additionalProps?.mobileYellowBannerDeskandTab;

  return (
    <Container className={classNames(classes.mobileWidget)}>
      <ManageBookingQuery />
      <Paper
        className={classNames(classes.paperRoot, {
          [classes.hideLoader]: !isLoading,
          [classes.mobileYellowBanner]: mobileYellowBannerDeskandTab,
        })}
      >
        <Grid container spacing={1.6}>
          <Grid item xs={12} md={3}>
            <Skeleton animation="wave" height={48} />
          </Grid>
          <Grid item xs={12} md={3}>
            <Skeleton animation="wave" height={48} />
          </Grid>
          <Grid item xs={12} md={3}>
            <Skeleton animation="wave" height={48} />
          </Grid>
          <Grid item xs={12} md={3}>
            <Skeleton animation="wave" height={48} />
          </Grid>
        </Grid>
      </Paper>
      <DynamicBookingForm
        guestLabels={{
          guestsTitleLabel: props?.GuestsSectionTitle,
          adultsTitleLabel: props?.AdultsLabel,
          adultsSubtitleLabel: props?.AdultsDescription,
          childrenTitleLabel: props?.ChildrenLabel,
          childrenSubtitleLabel: props?.ChildrenDescription,
          infantsTitleLabel: props?.InfantsLabel,
          infantsSubtitleLabel: props?.InfantsDescription,
          petsTitleLabel: props?.PetsLabel,
          petsSubtitleLabel: props?.PetsDescription,
          bedroomsTitleLabel: props?.BedroomsLabel,
          bedroomsSubTitleLabel: props?.BedroomsDescription,
          cabinsTitleLabel: props?.CabinsSectionTitle,
          wheelChairAdaptedTitleLabel: props?.DDALabel,
          wheelChairAdaptedSubtitleLabel: props?.DDADescription,
          accessibleIcon: dDAIconEl,
        }}
        formDisabled={isFormDisabled()}
        column={props?.BookingJourneyWidget}
        locations={bookableLocations || []}
        selectedLocationIds={locationIds || []}
        locationsLoading={locationsLoading}
        locationsLoaded={locationsLoaded}
        sortedLocations={sortedLocations}
        listOfCheckedLocationIds={listOfCheckedLocationIds}
        areAllSelected={areAllSelected}
        handleToggle={handleToggle}
        handleClearSelections={handleClearSelections}
        hoverLocationId={hoverLocationId}
        setHoverLocationId={(id) => setHoverLocationId(id)}
        handleUpdateLocation={(country, locationId, checked) => {
          handleUpdateLocation(country, locationId, checked, false);
        }}
        updateGuests={(type, value) => handleUpdateGuests(type, value)}
        onUpdateLocations={(selectedLocationIds, checked, bookingLocation) =>
          dispatch(
            selectLocation({
              selectedLocationIds,
              checked,
              bookingLocation,
            }),
          )
        }
        onToggleAllLocations={(checked) =>
          dispatch(selectAllLocations({ checked }))
        }
        onOpen={dropDownOpen}
        guests={guests}
        guestsConfig={{
          adults: {
            min: +props?.MiniumumAdults ?? 1,
            max: +props?.MaximumAdults ?? 9,
          },
          children: {
            min: +props?.MiniumumChildren ?? 0,
            max: +props?.MaximumChildren ?? 9,
          },
          infants: {
            min: +props?.MiniumumInfants ?? 0,
            max: +props?.MaximumInfants ?? 9,
          },
          pets: {
            min: +props?.MiniumumPets ?? 0,
            max: +props?.MaximumPets ?? 4,
          },
          bedrooms: {
            min: minNoOfBedrooms,
            max: +props?.MaximumBedrooms ?? 5,
          },
        }}
        dda={dda}
        toggleDda={toggleDda}
        dates={{
          ...{
            dateRange,
            focus,
            onFocusChange,
            setDateRange,
            setFocus,
            setCurrentYear,
            setCurrentMonth,
            currentMonth,
            currentYear,
            availableStartDates,
            calendarDatesWithCabinCount,
            calendarDatesWithCabinCountLoading,
            selectedYearFromQuickLinks,
            setSelectedYearFromQuickLinks,
            selectedMonthFromQuickLinks,
            setSelectedMonthFromQuickLinks,
          },
        }}
        cmsLabels={{
          locationDrawerTitle: props?.DrawerTitle,
          largeLocationLabel: props?.LocationLabel,
          compactLocationLabel: props?.CompactLocationLabel,
          largeGuestsLabel: props?.PartyLabel,
          compactGuestsLabel: props?.CompactGuestsLabel,
          arrivalLabel: props?.ArrivalDateLabel,
          departureLabel: props?.DepartureDateLabel,
          arrivalHelpText: props?.ArrivalHelpText,
          departureHelpText: props?.DepartureHelpText,
          compactDatesLabel: props?.CompactDatesLabel,
          touchedLocationLabel: locationsLabel,
          touchedGuestLabel: guestLabel,
          locationDrawerSelectAllLabel: props?.SelectAllLabel,
          locationIcon: locationIconEl,
          calendarIcon: calendarIconEl,
          partyIcon: partyIconEl,
          closeIcon: closeIconEl,
          locationDrawerCTALabel: props?.LocationDrawerCTALabel,
          partyDrawerCTALabel: props?.PartyDrawerCTALabel,
          legendUnavailableText: props?.LegendUnavailable,
          legendAvailableText: props?.LegendAvailable,
          legendSelectedText: props?.LegendSelected,
          legendSoldOutText: props?.LegendSoldOut,
          selectArrivalDateLabel: props?.SelectArrivalDateLabel,
          selectDepartureDateLabel: props?.SelectDepartureDateLabel,
          helpTextIcon: helpTextIconEl,
          checkAvailabilityCTALabel: props?.CheckAvailabilityButtonLabel,
          listViewLabel: props?.ViewLocationsAsListLabel,
          mapViewLabel: props?.ViewLocationsOnMapLabel,
          editSearchCTALabel: props?.EditSearchCTALabel,
        }}
        submit={onSubmit}
        isBookNowWidget={props?.BookNowWidget}
        showSearchSummary={showSearchSummary}
        setShowSearchSummary={(flag) => setShowSearchSummary(flag)}
        locationMap={
          <LocationMapComponent
            // contentId="LocationMap"
            contentValue={props?.location_map}
            overrideProps={{
              isWidget: true,
              listOfCheckedLocationIds: listOfCheckedLocationIds,
              handleUpdateLocation: (
                country: string,
                locationId: string,
                checked: boolean,
              ) => handleUpdateLocation(country, locationId, checked, true),
              infocardIndex: infocardIndex,
              setHoverLocationId: (id: string) => setHoverLocationId(id),
              hoverLocationId: hoverLocationId,
              setPopupTimer: (seconds: number) => setPopupTimer(seconds),
              isMapActive: isMapActive,
            }}
          />
        }
        showTradingPanelInCalendar={props.ShowTradingPanelInCalendar}
        tradingPanelTitle={props?.TradingPanelTitle || ""}
        tradingPanelImage={
          props?.TradingPanelImage?.data?.attributes?.url ?? undefined
        }
        tradingPanelImageAltText={
          props?.TradingPanelImage?.data?.attributes?.alternativeText ?? ""
        }
        tradingPanelMessage={
          <RichText
            contentId="tradingPanel"
            contentValue={
              props.TradingPanelMessage
                ? processRichText(props.TradingPanelMessage)
                : ""
            }
          />
        }
        tradingPanelLinkLabel={
          isAfter(new Date(props?.TradingPanelLinkMonthYear), new Date())
            ? props?.TradingPanelLinkLabel
            : undefined
        }
        tradingPanelLinkOnClick={
          isAfter(new Date(props?.TradingPanelLinkMonthYear), new Date())
            ? () => {
                const tradingPanelLinkMonthYear = new Date(
                  props?.TradingPanelLinkMonthYear,
                );
                const tradingPanelLinkMonth =
                  tradingPanelLinkMonthYear.getMonth() + 1;
                const tradingPanelLinkYear =
                  tradingPanelLinkMonthYear.getFullYear();
                if (tradingPanelLinkYear !== currentYear) {
                  setSelectedYearFromQuickLinks(tradingPanelLinkYear);
                }
                setSelectedMonthFromQuickLinks(
                  getPartialMonthNameFromMonthNumber(tradingPanelLinkMonth),
                );
              }
            : undefined
        }
        showQuickLinks={showQuickLinks}
        quickLinksForMonths={availableMonths}
        quickLinksForYears={availableYears}
        isLoading={isLoading}
        onLoad={() => setIsLoading(false)}
        reduceDropdownButtonInactiveWeight={
          !!props?.additionalProps?.reduceDropdownButtonInactiveWeight
        }
        bookingWidgetDropdownBtnShadow={
          !!props?.additionalProps?.bookingWidgetDropdownBtnShadow
        }
        removeDateLabelBookingWidget={
          !!props?.additionalProps?.removeDateLabelBookingWidget
        }
        searchRemoveOptionsFromGuests={
          !!props?.additionalProps?.searchRemoveOptionsFromGuests
        }
        bookingWidgetButtonLabels={
          !!props?.additionalProps?.bookingWidgetButtonLabels
        }
        fullWidthSideMenu={!!props?.additionalProps?.fullWidthSideMenu}
        calendarBlackOutlines={!!props?.additionalProps?.calendarBlackOutlines}
        mobileYellowBannerDeskandTab={mobileYellowBannerDeskandTab}
      />
    </Container>
  );
};

const BookingWidgetComponentTemplate = (props) => {
  const content =
    props?.content?.bookingWidget?.data?.[0]?.attributes ||
    props?.content?.data?.attributes;
  const pageImgQuality = props?.pageImgQuality;
  const additionalProps = props?.additionalProps;
  return withContent(
    BookingWidgetComponent,
    content,
    undefined,
    pageImgQuality,
    additionalProps,
  );
};

export default BookingWidgetComponentTemplate;
