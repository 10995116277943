import Image, { ImageProps } from "next/legacy/image";
import React, { PropsWithChildren } from "react";
import { cloudflareLoader } from "../../../../src/nextImageLoader";
import { makeStyles } from "@mui/styles";
import classNames from "classnames";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: 0,
  },
  imageFill: {
    "& img": {
      position: "absolute",
      boxSizing: "border-box",
      top: 0,
      left: 0,
      bottom: 0,
      right: 0,
      padding: 0,
      border: "none",
      margin: "auto",
      display: "block",
      width: 0,
      height: 0,
      minWidth: "100%",
      maxWidth: "100%",
      minHeight: "100%",
      maxHeight: "100%",
      objectFit: "cover",
    },
  },
}));

interface StaticImageData {
  src: string;
  height: number;
  width: number;
  blurDataURL?: string;
}

type ImageLoaderProps = {
  src: string;
  width: number;
  quality?: number;
};

export type ImageLoader = (resolverProps: ImageLoaderProps) => string;

export type FHNextImageProps = {
  src: string | StaticImageData;
  loader?: ImageLoader;
  alt?: string;
  layout?: "fill" | "fixed" | "intrinsic" | "responsive" | undefined;
  priority?: boolean;
  quality?: number;
  isBannerImg?: boolean;
} & ImageProps;

export const FHNextImage: React.FC<FHNextImageProps> = ({
  src,
  loader = cloudflareLoader,
  alt,
  layout,
  priority,
  quality,
  isBannerImg = false,
  ...props
}: PropsWithChildren<FHNextImageProps>) => {
  const classes = useStyles();
  return (
    <span
      className={classNames(classes.root, {
        [classes.imageFill]: layout === "fill",
      })}
    >
      {!!src && isBannerImg ? (
        <Image
          src={src}
          alt={!!alt ? alt : ""}
          layout={layout}
          quality={quality}
          priority={priority}
          {...props}
        />
      ) : (
        !!src && (
          <Image
            src={src}
            loader={loader}
            alt={!!alt ? alt : ""}
            layout={layout}
            priority={priority}
            {...props}
          />
        )
      )}
    </span>
  );
};
