import React, { PropsWithChildren, useRef } from "react";
import { makeStyles } from "@mui/styles";
import Typography from "@mui/material/Typography";
import { FHButton } from "../FHButton";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import { CheckboxField } from "../CheckboxField";
import { useForm } from "react-hook-form";
import TextOverflowScroll from "../TextOverflowScroll";
import {
  defaultMapPopupImageWidths,
  useGenerateResponsiveImageUrl,
} from "../../Utils";
import { FHNextImage, ImageLoader } from "../FHNextImage";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    padding: theme.spacing(0.5),
    width: "100%",
    boxSizing: "border-box",
  },
  locationInfo: {
    fontFamily: theme.typography.body1.fontFamily,
    padding: theme.spacing(0.5, 2, 1, 1),
    flex: 1,
  },
  locationImage: {
    marginLeft: "auto",
    width: 90,
    height: 90,
    flexBasis: 90,
    borderRadius: theme.shape.borderRadius * 2,
    position: "relative",
    overflow: "hidden",
  },
  locationBtn: {
    fontSize: theme.typography.body2.fontSize,
    marginTop: theme.spacing(0.5),
    padding: theme.spacing(0.2, 1),
  },
  selectLocation: {
    paddingTop: theme.spacing(1),
  },
  subHeading: {
    fontSize: 14,
    lineHeight: "20px",
  },
}));

export type MapCardPinPopupProps = {
  locationName: string;
  locationMapInfo: string;
  locationImage: string;
  locationURL?: string;
  viewLocationLabel?: string;
  setInfoIndex: (index: number) => void;
  hideTickboxes?: boolean;
  selectLocationLabel?: string | undefined;
  isWidget: boolean;
  listOfCheckedLocationIds?: string[];
  id: number;
  handleUpdateLocation?(
    country: string,
    locationId: string,
    checked: boolean
  ): void;
  popupSelectLabel?: string;
  popupSelectedLabel?: string;
  infoIndex?: number;
  country: string;
  imageLoader?: ImageLoader;
};

export const MapCardPinPopup: React.FC<MapCardPinPopupProps> = ({
  locationName,
  locationMapInfo,
  locationImage,
  viewLocationLabel,
  locationURL,
  setInfoIndex,
  selectLocationLabel,
  isWidget,
  listOfCheckedLocationIds,
  id,
  handleUpdateLocation = () => {},
  popupSelectLabel = "select",
  popupSelectedLabel = "selected",
  infoIndex,
  country,
  imageLoader,
}: PropsWithChildren<MapCardPinPopupProps>) => {
  const classes = useStyles();
  const { control } = useForm({ mode: "all" });
  const LocationLabelreference = useRef(null);
  const LocationMapInfoReference = useRef(null);

  return (
    <ClickAwayListener
      onClickAway={() => {
        setInfoIndex(0);
      }}
      touchEvent={false}
    >
      <div className={classes.root}>
        <div className={classes.locationInfo}>
          <Typography id={`heading_${String(id)}`} ref={LocationLabelreference}>
            <strong>
              <TextOverflowScroll
                overFlowText={locationName}
                containerWidth={
                  document.getElementById(`heading_${String(id)}`)?.clientWidth
                }
                parentElementReference={LocationLabelreference}
              />
            </strong>
          </Typography>
          {locationMapInfo && (
            <Typography
              variant="body2"
              id={`subHeading_${String(id)}`}
              ref={LocationMapInfoReference}
              className={classes.subHeading}
            >
              <TextOverflowScroll
                overFlowText={locationMapInfo}
                containerWidth={
                  document.getElementById(`subHeading_${String(id)}`)
                    ?.clientWidth
                }
                parentElementReference={LocationMapInfoReference}
              />
            </Typography>
          )}
          {locationURL && !isWidget && (
            <FHButton
              fhSize="sm"
              className={classes.locationBtn}
              href={locationURL}
            >
              {viewLocationLabel}
            </FHButton>
          )}
          {isWidget && (
            <div className={classes.selectLocation}>
              <CheckboxField
                name={popupSelectLabel || "select"}
                label={
                  listOfCheckedLocationIds?.includes(String(id))
                    ? popupSelectedLabel
                    : popupSelectLabel
                }
                control={control}
                defaultValue={listOfCheckedLocationIds?.includes(String(id))}
                customOnChange={(checked: boolean) => {
                  handleUpdateLocation(country, String(id), checked);
                }}
              />
            </div>
          )}
        </div>
        <div className={classes.locationImage}>
          <FHNextImage
            src={useGenerateResponsiveImageUrl(
              locationImage,
              defaultMapPopupImageWidths,
              80,
              true
            )}
            alt={locationName}
            layout="fill"
            objectFit="cover"
            loading="lazy"
            loader={imageLoader}
          />
        </div>
      </div>
    </ClickAwayListener>
  );
};
