import React, { useState, useEffect } from "react";
import { MapLocations } from "../../interfaces/locations";
import { BookableLocation } from "@generated/types";
import { Container } from "@mui/material";
import { useLocationsSelector } from "@/store/selectors";
import StaticMapCard, {
  Location,
} from "@components/SemanticTheme/StaticMapCard";
import { updateAbsoluteLink } from "@components/Utils";

export type LocationMapOverrideProps = {
  handleUpdateLocation?: (
    country: string,
    locationId: string,
    checked: boolean,
  ) => void;
  isWidget: boolean;
  showCompareLocations?: boolean;
  listOfCheckedLocationIds?: string[];
  infocardIndex: number;
  setHoverLocationId: (id: string) => void;
  hoverLocationId: string;
  setPopupTimer: (seconds: number) => void;
  isMapActive?: boolean;
};

type Pins = {
  id: string;
  Name: string;
  LocationID: string;
  Top: number;
  Left: number;
};

const LocationMapComponent = ({ contentValue, overrideProps }) => {
  const props = contentValue?.data?.attributes;
  const { mapLocations } = useLocationsSelector();
  const [newPlacesData, setNewPlacesData] = useState<MapLocations[]>([]);
  const [pins, setPins] = useState<Pins[]>([]);
  const [locations, setLocations] = useState<Location[]>([]);

  useEffect(() => {
    if (props?.PopUpTime && overrideProps?.isWidget) {
      overrideProps?.setPopupTimer(Number(props?.PopUpTime) * 1000 || 0);
    }
  }, [props?.PopUpTime]);

  useEffect(() => {
    if (props?.Pins) {
      const _pins = props?.Pins?.map((pin: Pins) => ({
        id: pin?.id,
        Name: pin?.Name,
        LocationID: pin?.LocationID,
        Left: pin?.Left,
        Top: pin?.Top,
      }));
      setPins(_pins as Pins[]);
    }
  }, []);

  useEffect(() => {
    if (mapLocations) {
      const locationsData = mapLocations?.map((location: BookableLocation) => ({
        id: location?.id,
        name: location?.locationName,
        info: location?.region,
        country: location?.country,
        latitude: location?.latitude,
        longitude: location?.longitude,
        cabinImgUrl: location?.locationImage?.url || "",
        locationURL: location?.locationMapViewUrl || "",
      }));
      setNewPlacesData(JSON.parse(JSON.stringify(locationsData)));
    }
  }, [mapLocations]);

  useEffect(() => {
    if (newPlacesData && pins) {
      const mergedArray: Location[] = newPlacesData
        .map((mapLocation) => {
          const pin = pins.find(
            //checking if left & top are defined in pins
            (pin) => pin.Name === mapLocation.name && pin.Left && pin.Top,
          );
          const selected = overrideProps?.listOfCheckedLocationIds?.find(
            //showing selected location pin
            (x) => x === mapLocation.id.toString(),
          );
          const isHover =
            overrideProps?.hoverLocationId === mapLocation.id.toString();
          if (pin) {
            return {
              id: mapLocation.id,
              left: pin.Left,
              top: pin.Top,
              name: mapLocation.name,
              infoText: mapLocation.info,
              imageUrl: mapLocation.cabinImgUrl,
              country: mapLocation.country,
              selected: selected,
              isHover: isHover,
              locationURL: mapLocation?.locationURL,
            };
          } else return;
        })
        .filter((location) => location !== undefined) as Location[];
      setLocations(mergedArray);
    }
  }, [
    newPlacesData,
    pins,
    overrideProps?.listOfCheckedLocationIds,
    overrideProps?.hoverLocationId,
  ]);

  return (
    <Container>
      <StaticMapCard
        mapUrl={props?.StaticMapImage?.data?.attributes?.url}
        mapPin={`${
          props?.PinDefaultStateIcon?.data?.attributes?.url ||
          "http://maps.google.com/mapfiles/ms/icons/blue.png"
        }`}
        mapPinHover={`${
          overrideProps?.setHoverLocationId
            ? props?.PinHighlightedStateIcon?.data?.attributes?.url ||
              "http://maps.google.com/mapfiles/ms/icons/red.png"
            : null
        }`}
        mapPinSelected={
          props?.PinSelectedStateIcon?.data?.attributes?.url ||
          "http://maps.google.com/mapfiles/ms/icons/orange.png"
        }
        locations={locations}
        overrideProps={overrideProps}
        viewLocationLabel={props?.ViewLocationLabel}
        compareLocationLabel={props?.CompareLocationsCTALabel}
        compareLocationURL={updateAbsoluteLink(
          props?.CompareLocationsCTAURL?.data?.attributes?.PageRoute,
        )}
      />
    </Container>
  );
};

export default LocationMapComponent;
